import axios from "axios";

Number.prototype.between = function(a, b) {
    var min = Math.min.apply(Math, [a, b]),
        max = Math.max.apply(Math, [a, b]);
    return this > min && this < max;
};

export const mapUtil = {
    async changeMapAddr(latlng, jibunAddress, roadAddress){
        return new Promise((resolve,reject)=>{

            let infoWindowContent = [];
            let lng = latlng[1] * 1;
            let coordType = '';
            if (lng.between(124.5, 126)) {
                coordType = 'west';
            } else if (lng.between(126, 128)) {
                coordType = 'center';
            } else if (lng.between(128, 130)) {
                coordType = 'east';
            } else {
                coordType = 'center';
            }

            console.log(coordType);

            if (roadAddress === '' && jibunAddress === '') {
                window.setModalMessage('올바른 주소를 입력 혹은 클릭해주세요.');
                return -1;
            }

            
            window.reqLoad = axios({
                method: 'get',
                url: process.env.REACT_APP_MAP_SERVER_URL,
                params: {
                    address: (roadAddress === '') ? jibunAddress : roadAddress,
                    addressType: (roadAddress === '') ? 'PARCEL' : 'road',
                    'coordType' : coordType
                }
                }).then(data => {
                    console.log(data);
                    data = data.data;
                    if(data === '검색 결과가 없습니다.'){
                        window.setModalMessage('검색 결과가 없습니다.');
                        return;
                    }
                    data.name = {
                        roadAddress : roadAddress,
                        jibunAddress : jibunAddress
                    }
                    if (data.status === "fail"){
                        console.log(data.message.message);
                        return;
                    }
                    if (jibunAddress !== '') {
                        infoWindowContent.push(jibunAddress);
                    }
                    if(!data.construct){
                        window.setModalMessage('검색에 실패하였습니다.');
                        return;
                    }
                    
                    let constructInfo = data.construct;
                    let PLOT_AR = constructInfo.PLOT_AR; // 토지 면적
                    let TOTAR = constructInfo.TOTAR; // 연면적
                    let SHAPE_PATH = [];
                    let landInfo = data.land;
                    let SHAPE = landInfo.shapes;
                    SHAPE.forEach(item => {
                        SHAPE_PATH.push(
                            window.naver.maps.UTMK.fromUTMKToCoord(
                                new window.naver.maps.Point(item.x, item.y)
                            )
                        );
                    });

                    if (landInfo.type === "하천"){
                        window.setModalMessage("하천은 선택하실 수 없습니다.");
                        return;
                    } else if (landInfo.type === "도로"){
                        window.setModalMessage("도로는 선택하실 수 없습니다.");
                        return;
                    }
                    if (window.mapPoly)
                        window.mapPoly.setMap(null);

                    if (SHAPE_PATH.length !== 0){
                        window.mapPoly = new window.naver.maps.Polygon({
                            paths: [
                                SHAPE_PATH
                            ],
                            fillColor: '#AB35D5',
                            fillOpacity: 0.15,
                            strokeColor: 'url(#myGradient)',
                            strokeOpacity: 0.6,
                            strokeWeight: 3
                        })
                        window.mapPoly.setMap(window.map);
                    }
                    document.body.scrollIntoView(false);

                    if(landInfo.center === undefined){
                        window.setModalMessage('검색에 실패하였습니다.');
                        return;

                    }
                    
                    window.infoWindow.open(window.map,
                        window.naver.maps.UTMK.fromUTMKToCoord(
                            new window.naver.maps.Point(landInfo.center.x, landInfo.center.y)
                        )
                    );
                    resolve(data);
                    
                }).catch(error =>{
                    console.log(error);
                    reject(error);
                })
        });
        
    },
    searchCoordinateToAddress(latlng) {
  
        window.naver.maps.Service.reverseGeocode({
            coords: latlng,
            orders: [
                window.naver.maps.Service.OrderType.ADDR,
                window.naver.maps.Service.OrderType.ROAD_ADDR
            ].join(',')
        }, function(status, response) {
            if (status === window.naver.maps.Service.Status.ERROR) {
                if (!latlng) {
                    return alert('ReverseGeocode Error, Please check latlng');
                }
                if (latlng.toString) {
                    return alert('ReverseGeocode Error, latlng:' + latlng.toString());
                }
                if (latlng.x && latlng.y) {
                    return alert('ReverseGeocode Error, x:' + latlng.x + ', y:' + latlng.y);
                }
                return alert('ReverseGeocode Error, Please check latlng');
            }
            window.map.panTo(latlng);
            mapUtil.changeMapAddr(latlng, response.v2.address.jibunAddress, response.v2.address.roadAddress).then(data=>{
                window.setAddressInfo(data);
            }).catch(error=>{
                console.log(error);
                window.setModalMessage(error);
            })
  
        });
      },

    searchAddressToCoordinate(address) {
        window.naver.maps.Service.geocode({
            query: address
        }, function(status, response) {
            if (status === window.naver.maps.Service.Status.ERROR) {
                if (!address) {
                    window.setModalMessage('주소를 찾을 수 없습니다.');
                    return;
                }
                return alert('Geocode Error, address:' + address);
            }
  
            if (response.v2.meta.totalCount === 0) {
                window.setModalMessage('주소를 찾을 수 없습니다.');
                return;
            }
            window.map.panTo([response.v2.addresses[0].x, response.v2.addresses[0].y]);
            mapUtil.changeMapAddr([response.v2.addresses[0].x, response.v2.addresses[0].y], response.v2.addresses[0].jibunAddress, response.v2.addresses[0].roadAddress).then(data=>{
                window.setAddressInfo(data);
            }).catch(error=>{
              console.log(error);
              window.setModalMessage(error);
            })
            // window.map.setCenter(point);
        });
    },

    async addressToCoordinateForTest(address) {
        return new Promise((resolve, reject) => {
            window.naver.maps.Service.geocode({
                query: address
            }, function(status, response) {
                if (status === window.naver.maps.Service.Status.ERROR) {
                    if (!address) {
                        window.setModalMessage('주소를 찾을 수 없습니다.');
                        reject('주소 오류');
                        return;
                    }
                    reject(new Error('Geocode Error'));
                    return;
                }
        
                if (response.v2.meta.totalCount === 0) {
                    window.setModalMessage('주소를 찾을 수 없습니다.');
                    reject('검색 결과 없음');
                    return;
                }
                
                // 지도 이동 및 처리
                // window.map.panTo([response.v2.addresses[0].x, response.v2.addresses[0].y]);
                mapUtil.changeMapAddr([response.v2.addresses[0].x, response.v2.addresses[0].y], response.v2.addresses[0].jibunAddress, response.v2.addresses[0].roadAddress)
                    .then(data => {
                        window.setAddressInfo(data);
                        resolve(data); // 검색 결과를 반환
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
            });
        });
    },

    bookMarkSearch(address) {
        return new Promise((resolve, reject) => {
            window.naver.maps.Service.geocode({
                query: address
            }, function(status, response) {
                if (status === window.naver.maps.Service.Status.ERROR) {
                    if (!address) {
                        window.setModalMessage('주소를 찾을 수 없습니다.');
                        reject('주소를 찾을 수 없습니다.');
                        return;
                    }
                    alert('Geocode Error, address:' + address);
                    reject('Geocode Error');
                    return;
                }
    
                if (response.v2.meta.totalCount === 0) {
                    window.setModalMessage('주소를 찾을 수 없습니다.');
                    reject('주소를 찾을 수 없습니다.');
                    return;
                }
                



    
                mapUtil.getAddrInfo([response.v2.addresses[0].x, response.v2.addresses[0].y], response.v2.addresses[0].jibunAddress, response.v2.addresses[0].roadAddress)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.log(error);
                        window.setModalMessage(error);
                        reject(error);
                    });
            });
        });
    },
    async getAddrInfo(latlng, jibunAddress, roadAddress){
        return new Promise((resolve,reject)=>{

            let infoWindowContent = [];
            let lng = latlng[1] * 1;
            let coordType = '';
            if (lng.between(124.5, 126)) {
                coordType = 'west';
            } else if (lng.between(126, 128)) {
                coordType = 'center';
            } else if (lng.between(128, 130)) {
                coordType = 'east';
            } else {
                coordType = 'center';
            }

            console.log(coordType);

            if (roadAddress === '' && jibunAddress === '') {
                window.setModalMessage('올바른 주소를 입력 혹은 클릭해주세요.');
                return -1;
            }


            window.reqLoad = axios({
                method: 'get',
                url: process.env.REACT_APP_MAP_SERVER_URL,
                params: {
                    address: (roadAddress === '') ? jibunAddress : roadAddress,
                    addressType: (roadAddress === '') ? 'PARCEL' : 'road',
                    'coordType' : coordType
                }
                }).then(data => {
                    data = data.data;
                    data.name = {
                        roadAddress : roadAddress,
                        jibunAddress : jibunAddress
                    }
                    if (data.status === "fail"){
                        console.log(data.message.message);
                        return;
                    }
                    if (jibunAddress !== '') {
                        infoWindowContent.push(jibunAddress);
                    }
                    if(!data.construct){
                        window.setModalMessage('검색에 실패하였습니다.');
                        return;
                    }
                    
                    let landInfo = data.land;

                    if (landInfo.type === "하천"){
                        window.setModalMessage("하천은 선택하실 수 없습니다.");
                        return;
                    } else if (landInfo.type === "도로"){
                        window.setModalMessage("도로는 선택하실 수 없습니다.");
                        return;
                    }
                    resolve(data);
                    
                }).catch(error =>{
                    console.log(error);
                    reject(error);
                })
        });
        
    },
    



}