import { useEffect, useState } from "react";
import Header from "./Header";

function Viewer3D(props){
    const [hoverIndex,setHoverIndex] = useState(-1);
    const imageArray = [
        {name : 'Casa de verde',href : "https://3d.codegream.com/site_n2/"},
        {name : 'Casa Batlló',href : "https://3d.codegream.com/type_b3/"},
        {name : `Portal de l'Àngel`,href : "https://3d.codegream.com/type_b3/"},
        {name : 'Carrer de Calàbria',href : "https://3d.codegream.com/type_b3/"},
        {name : 'Casa Elizalde',href : "https://3d.codegream.com/type_b3/"}
    ]

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
    window.addEventListener('resize', function () {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
    });

    return (
        <div id="Viewer" onMouseLeave={()=>{setHoverIndex(-1)}}>
        <Header userInfo={props.userInfo} logout={props.logout}/>
            <main>
                <ul className="viewList">{/* .vertical-rlEN {writing-mode: vertical-rl;}     -------- 영어 90도 회전 css */}
                    {imageArray.map((item,index)=>{
                        return(
                            <li className={hoverIndex === index ? "hover":""} key={index} onMouseEnter={()=>{setHoverIndex(index)}}>
                                <a className="href" href={item.href} target="_blank">                                    
                                    <div className="img"></div>
                                    <div className='text_wrap'>
                                        <p className="main_title">{item.name}</p>
                                        <div className="txt_box">
                                            <p className="sub_title">{item.name}</p>
                                            <a href={item.href} target="_blank">
                                                3D웹사이트 보러가기
                                                <img src={process.env.PUBLIC_URL+"../images/3Dviewer/3D_arrow.svg" } alt='3D웹사이트 보러가기' />
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        )
                    })}                    
                </ul>
            </main>
        </div>
    )
}

export default Viewer3D;