    import React, { useState, useEffect } from 'react';

    function Section01() {
    const [currentInfoIndex, setCurrentInfoIndex] = useState(0);
    const infoList = [
        {
        image: '../../images/main_bnr.svg',
        h1: process.env.PUBLIC_URL+"images/logoWhite.png",
        h2: '집을 짓는 새로운 방식, 프롭메이트'
        },
        {
        image: '../../images/changebg1.jpg',
        title: '오직 당신만을 위한 최적화된 디자인',
        description: '단 하나의 디자인을 만들어 냅니다. 고객 만족을 최우선으로한 프롭메이트만의 디자인을 체험해보세요.'
        },
        {
        image: '../../images/changebg2.jpg',
        title: '엄선된 전문 업체로 최상의 시공성',
        description: '다양한 포트폴리오를 가진 최고의 업체와 함께합니다. 그들의 작품을 감상해보세요.'
        },
        {
        image: '../../images/changebg3.jpg',
        title: '고객 예산에 맞춘 금융 컨설팅',
        description: '고객 예산 안에 최적화된 자금 활용 방안을 확인해보세요. 언제든 상담해 드립니다.'
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentInfoIndex((prevIndex) => (prevIndex + 1) % infoList.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
        <div className="section_01 sections">
            <div className="width_con">
            <ul className="wrap1_info">
                {infoList.map((info, index) => (/* map은 jsx에서의 forEach */
                <li
                    key={index}
                    className={`info_item ${index === currentInfoIndex ? 'active' : ''}`}
                    style={{  backgroundImage: `url(${info.image})` }}
                >
                    <div className='first_txt'>
                        <img src={info.h1}></img>
                        <p>{info.h2}</p>
                    </div>
                    <div className="info_txt">
                        <h2>{info.title}</h2>
                        <p>{info.description}</p>
                    </div>
                </li>
                ))}
            </ul>
            </div>
        </div>
        </>
    );
    }

    export default Section01;
