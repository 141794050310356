import { useState, useEffect, useRef } from "react";
import Section01 from "./mobile_sections/MSection01";
import Section02 from "./mobile_sections/MSection02";
import Section03 from "./mobile_sections/MSection03";
import Section04 from "./mobile_sections/MSection04";
import Section05 from "./mobile_sections/MSection05";
import Section06 from "./mobile_sections/MSection06";
import FullpageHeader from "./MFullpageHeader";
let scrolling;

function Fullpage(props) {
  const fullBody = document.body;

  const outerDivRef = useRef();//리액트내에서 도큐먼트 조작시에는 Ref를 사용하는게 좋음
  const [scrollIndex, setScrollIndex] = useState(1);
  fullBody.classList.add('fixed');
  let vh = 0;
  const pages = [
    { id: 1, content: <Section01/>},
    { id: 2, content: <Section02 setScrollIndex={setScrollIndex} outerDivRef={outerDivRef}/>},
    { id: 3, content: <Section03/>},
    { id: 4, content: <Section04/>},
    { id: 5, content: <Section05 userInfo={props.userInfo}/>},
    { id: 6, content: <Section06/>},
  ];
  useEffect(()=>{
    outerDivRef.current.parentElement.classList.add("white");
    const pageHeight = window.innerHeight + 1;    
    vh = pageHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    props.checkLogin();
    return()=>{
      fullBody.classList.remove('fixed');
      }

  },[])
  useEffect(() => {
    const resize = () => {
      const pageHeight = window.innerHeight + 1;
      

      vh = pageHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      outerDivRef.current.style.height = `${pageHeight}px`;
      outerDivRef.current.style.transform = `translateY(-${pageHeight * (scrollIndex - 1)}px)`;
    };
    const scrollEventHandler = (e) => {
      if(scrolling) return;
      const deltaY = e.deltaY;//마우스 휠에서의 수직방향 스크롤 변화량
      const pageHeight = window.innerHeight;//현재page의 height가져옴
      scrolling = true;      
      setTimeout(() => {
        scrolling = false;
      }, 500);
      const scrollIndexChange = deltaY > 0 ? 1 : -1;//휠다운이냐 휠업이냐
      const newScrollIndex = scrollIndex + scrollIndexChange;
      if(newScrollIndex ==0 || newScrollIndex > pages.length) return false;
      const scrollToTop = pageHeight * (newScrollIndex - 1);
      outerDivRef.current.style = `transform: translateY(-${scrollToTop}px)`;
      setScrollIndex(newScrollIndex);
      // if(newScrollIndex === 1){fullBody.classList.remove('fixed');}
      if(newScrollIndex === 1){
        outerDivRef.current.parentElement.classList.add("white");
      }else{
        outerDivRef.current.parentElement.classList.remove("white");
      }

      
    };
    function hasSwiperParent(element) {
      let parent = element.parentNode;
      
      while (parent) {
        if (parent.classList && parent.classList.contains('swiper')) {
          return true;
        }
        parent = parent.parentNode;
      }
      
      return false;
    }
    const wheelHandler = (e) => {
      e.preventDefault();
      scrollEventHandler(e);
    };
    const touchStartHandler = (e) => {
      const touchStartY = e.touches[0].clientY;
      const touchStartX = e.touches[0].clientX;
      outerDivRef.current.dataset.touchStartX = touchStartX;
      outerDivRef.current.dataset.touchStartY = touchStartY;
    };
    const touchEndHandler = (e) => {
      const touchEndY = e.changedTouches[0].clientY;
      const touchStartY = outerDivRef.current.dataset.touchStartY;
      const deltaY = touchEndY - touchStartY;
      if(hasSwiperParent(e.target)){
        const touchEndX = e.changedTouches[0].clientX;
        const touchStartX = outerDivRef.current.dataset.touchStartX;
        const deltaX = touchEndX - touchStartX;
        if(Math.abs(deltaX) > Math.abs(deltaY)){return false}
      }
      
      if (deltaY !== 0) {
        scrollEventHandler({ deltaY: -deltaY });
      }
    };


    const outerDivRefCurrent = outerDivRef.current;
    outerDivRefCurrent.addEventListener("wheel", wheelHandler);
    outerDivRefCurrent.addEventListener("touchstart",touchStartHandler);


    outerDivRefCurrent.addEventListener("touchend",touchEndHandler);

    window.addEventListener('resize',resize);
    return () => {
      outerDivRefCurrent.removeEventListener("wheel", wheelHandler);
      outerDivRefCurrent.removeEventListener("touchstart", touchStartHandler);
      outerDivRefCurrent.removeEventListener("touchend", touchEndHandler);
      window.removeEventListener('resize',resize);
    };
  }, [scrollIndex]);

  return (
    <div id="MFullpage">
      <FullpageHeader userInfo={props.userInfo} logout={props.logout}/>
      <div ref={outerDivRef} className="outer">
        {pages.map((page, index) => (
          <div key={page.id}>
            {page.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Fullpage;