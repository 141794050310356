import React, { useRef, useState } from 'react';
/* swiper react components---------------------------------------------------------------------------------------------------------- */
import { Swiper, SwiperSlide } from 'swiper/react';
/* swiper react modules---------------------------------------------------------------------------------------------------------- */
import {EffectCoverflow, Autoplay} from 'swiper/modules';
/* swiper react styles---------------------------------------------------------------------------------------------------------- */
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-coverflow';

function Section02 ({setScrollIndex , outerDivRef}){
    const clickFunction = (e) => {
        const index = parseInt(e.currentTarget.getAttribute('index'));        
        setScrollIndex(index + 2);
        console.log(index + 2);
        outerDivRef.current.style = `transform: translateY(-${window.innerHeight * (index + 1)}px)`;
    }
    return (
        <>
        <div className='section_02 sections'>
            <Swiper
                effect={'coverflow'}
                centeredSlides={true}
                slidesPerView={'auto'}
                spaceBetween={100}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                speed={1500}
                coverflowEffect={{
                rotate: 10,
                stretch: 100,
                depth: 150,
                slideShadows : false,
                modifier: 1,
                }}
                modules={[EffectCoverflow,Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide className='swiper-slide'onClick={clickFunction} index={1}>
                    <div className='img_box none_full'><img src={process.env.PUBLIC_URL+"images/section_02/image_01_color.jpg"}/></div>
                    <div className='swiper_txt'>
                        <h2>지도 기반 토지정보 확인 시스템</h2>
                        <p>원하는 땅을 찾아보세요.<br/>얼마에 어떤 건물을 지을 수 있는지 직접 확인하세요.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide'onClick={clickFunction} index={2}>
                    <div className='img_box none_full'><img src={process.env.PUBLIC_URL+"images/section_02/image_02_color.jpg"}/></div>
                    <div className='swiper_txt'>
                        <h2>언제 어디서나, <br className='second_br'/>3D Veiwer</h2>
                        <p>Web기반의 3D뷰어로,<br/>언제 어디서나 여러분의 계획안을 직관적으로 확인하세요.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide'onClick={clickFunction} index={3}>
                    <div className='img_box none_full'><img src={process.env.PUBLIC_URL+"images/section_02/image_03_color.jpg"}/></div>
                    <div className='swiper_txt'>
                        <h2>집 짓는데 필요한 <br className='second_br'/>필수 정보 컨설팅</h2>
                        <p>집을 짓기에 필요한 수많은 궁금증<br/>언제든 물어보고 결정하세요.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide'onClick={clickFunction} index={1}>
                    <div className='img_box none_full'><img src={process.env.PUBLIC_URL+"images/section_02/image_01_color.jpg"}/></div>
                    <div className='swiper_txt'>
                        <h2>지도 기반 토지정보<br className='second_br'/> 확인 시스템</h2>
                        <p>원하는 땅을 찾아보세요.<br/>얼마에 어떤 건물을 지을 수 있는지 직접 확인하세요.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide'onClick={clickFunction} index={2}>
                    <div className='img_box none_full'><img src={process.env.PUBLIC_URL+"images/section_02/image_02_color.jpg"}/></div>
                    <div className='swiper_txt'>
                        <h2>언제 어디서나, 3D Veiwer</h2>
                        <p>Web기반의 3D뷰어로,<br/>언제 어디서나 여러분의 계획안을 직관적으로 확인하세요.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide'onClick={clickFunction} index={3}>
                    <div className='img_box none_full'><img src={process.env.PUBLIC_URL+"images/section_02/image_03_color.jpg"}/></div>
                    <div className='swiper_txt'>
                        <h2>집 짓는데 필요한 필수 정보 컨설팅</h2>
                        <p>집을 짓기에 필요한 수많은 궁금증<br/>언제든 물어보고 결정하세요.</p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        </>
    );
}
export default Section02;