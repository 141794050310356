import { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useLocation } from "react-router-dom";

function JoinAgree (props){

      // 현재 선택된 탭의 인덱스를 state로 관리합니다.
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const location = useLocation();
    useEffect(()=>{
        if(location.state.index === undefined){return false}
        setActiveTabIndex(location.state.index);
    },[])
    // 클릭한 탭에 해당하는 인덱스를 받아와서 activeTabIndex를 변경합니다.
    const handleTabClick = (index) => {
        setActiveTabIndex(index);
    };

    // 각 탭에 대한 내용을 정의합니다. 여기서는 이미지를 사용하도록 예시를 들었습니다.
    const tabContents = [
        <div>
        {/* 첫 번째 이미지 세트 */}
            <img src={process.env.PUBLIC_URL+"images/agree/first/a.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/first/b.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/first/c.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/first/d.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/first/e.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/first/f.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/first/g.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/first/h.jpg"}></img>
        </div>,
        <div>
            <img src={process.env.PUBLIC_URL+"images/agree/second/a.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/second/b.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/second/c.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/second/d.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/second/e.jpg"}></img>
        {/* 두 번째 이미지 세트 */}
        </div>,
        <div>
            <img src={process.env.PUBLIC_URL+"images/agree/third/a.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/third/b.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/third/c.jpg"}></img>
            <img src={process.env.PUBLIC_URL+"images/agree/third/d.jpg"}></img>
        {/* 세 번째 이미지 세트 */}
        </div>,
        <div>
            <img src={process.env.PUBLIC_URL+"images/agree/fourth.jpg"}></img>
        {/* 네 번째 이미지 세트 */}
        </div>
    ];
    
    const tabNames = [
        // 각 탭의 이름을 정의합니다.
        "이용약관",
        "개인정보처리방침",
        "위치기반서비스",
        "프로모션 정보 수신"
    ];

    return(
        <div id="joinagree">
            <Header userInfo={null} logout={props.logout}></Header>
            <main>
                <div className="agree_all">
                <ul className="list_tab">
                    {tabNames.map((name, index) => (
                    <li key={index}>
                    <a
                        href="#"
                        className={activeTabIndex === index ? "active" : ""}
                        onClick={() => handleTabClick(index)}
                    >
                        {name}
                    </a>
                    </li>
                    ))}
                </ul>
                <ul className="agree_contents">
                    {/* 현재 선택된 탭의 내용을 보여줍니다. */}
                    {tabContents.map((content, index) => (
                    <li key={index} className={activeTabIndex === index ? "active" : ""}>
                        {content}
                    </li>
                    ))}
                </ul>
                </div>
            </main>
            <div className="footer_bg">
                <footer>
                    <ul className="footer_info">
                        <li><address>주소 | 서울시 관악구 문성로 199-1 2층</address></li>
                        <li>대표 | 고호준</li>
                        <li>개인정보 관리 책임자 | 이형우</li>
                        <li>사업자등록번호 | 628-40-00876</li>
                    </ul>
                    <ul className="footer_link">
                        <li><Link to={'/Company'}>회사소개</Link></li>
                        <li><Link to={'/agree'} state={{ index : 0}}>이용약관</Link></li>
                        <li><Link to={'/agree'} state={{ index : 1}}>개인정보처리방침</Link></li>
                        <li><Link to={'/agree'} state={{ index : 2}}>위치기반 서비스 이용약관</Link></li>
                    </ul>
                    <p>Copyright 2022 propmate Co. Ltd. all rights reserved.</p>
                </footer>
            </div>
        </div>
    )

}

export default JoinAgree;