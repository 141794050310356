import { Link } from "react-router-dom";


function Section06 () {
    return(
        <>
        <div className="section_06 sections">
            <ul className="text_con">
                <li>
                    <p className="title"><span>Mail</span></p>
                    <p className="constent">propmate.it@gmail.com</p>
                </li>
                <li>
                    <p className="title"><span>IR</span></p>
                    <p className="constent">pdf.download</p>
                </li>
            </ul>
            <div className="info_con">
                <div className="image">
                    <img src={process.env.PUBLIC_URL+"images/section_06/footer_img2.svg"}></img>
                </div>
                <div className="text_link">
                    <ul className="info_txt">
                        <li>대표 | 고호준</li>
                        <li>개인정보 관리 책임자 | 이형우</li>
                        <li>사업자등록번호 | 469-81-03262</li>
                    </ul>
                    <ul className="link_txt">
                        <li><Link to={'/company'}>회사소개</Link></li>
                        <li><Link to={'/agree'} state={{ index : 0}}>이용약관</Link></li>
                        <li><Link to={'/agree'} state={{ index : 1}}>개인정보처리방침</Link></li>
                        <li><Link to={'/agree'} state={{ index : 2}}>위치기반 서비스 이용약관</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}

export default Section06;