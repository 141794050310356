import React, { useEffect,useState } from 'react';
import emailjs from '@emailjs/browser';


function Consulting(props){
    const [budget,setBudget]= useState([0,1]);
    const [consultingUlState,setConsultingUlState] = useState(false);
    const [checkedConsulting,setCheckedConsulting] = useState([]);
    const [submitBtnState,setSubmitBtnState] = useState('disabled');
    const [text,setText] = useState('컨설팅 받고자 하는 항목들을 선택해주세요. (중복선택 가능)');
    const [inputText,setInputText] = useState('컨설팅 받고자하는 건축용도를 입력해주세요. ex)단독주택/다가구주택');
    const budget_amount = ['3억','6억','10억','20억','30억'];
    const checkbox_list = ['현재 보고 있는 토지','설계 / 인허가','시공','인테리어','예상 공사비 / 예상소요기간','토지 매입','기타 (상세문의 작성)'];
    
    function selectTitleOnclick (){
        if(consultingUlState === true){
            setConsultingUlState(false)
        }else{
            setConsultingUlState(true)
        }
    }

    function checkBoxOnChange(e){
        if(e.target.checked){
        setCheckedConsulting([...checkedConsulting,e.target.value]);
        }else{
            setCheckedConsulting(checkedConsulting.filter(option=>option  !== e.target.value));
        }
    }

    function consultingSubmit(){
        if(props.userInfo === null){window.setModalMessage('로그인 후 이용가능합니다.');props.setConsultingState(false);return false;}
        const form = document.consultingForm;
        

        const templateParams = {
            consulting_items : checkedConsulting,
            architectural_use : form.architectural_use.value,
            consulting_budget : [budget_amount[budget[0]],budget_amount[budget[1]]],
            consulting_detail_input : form.consulting_detail_input.value,
            user_info : props.userInfo
        }

        console.log(templateParams);

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,process.env.REACT_APP_EMAILJS_TEMPLATE_ID,templateParams,process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result)=>{
            console.log(result.text);
            alert('컨설팅 접수가 완료 되었습니다. 영업일 기준 5일이내로 답변 드리겠습니다. 모든 접수는 순차적으로 답변 드립니다. 감사합니다.');
            window.setModalMessage('컨설팅 문의를 보냈습니다.');props.setConsultingState(false);

        }).catch((error)=>{
            console.log(error.text);
        })
    }

    function haveParents(e){
        const parentHasConsultingSelect = e.target.closest('.consulting_select') !== null;
        return parentHasConsultingSelect;
    }

    function consulting_select_state(e){
        if(haveParents(e) === true){
            setConsultingUlState(true);
        }else{
            setConsultingUlState(false);
        }
    }

    function consultingFirst(){
        console.log(window.innerWidth);
        if(window.innerWidth <= 768){
            setText('중복선택 가능');
        }
    }
    function consultingSecond(){
        console.log(window.innerWidth);
        if(window.innerWidth <= 768){
            setInputText('ex)단독주택/다가구주택');
        }
    }

    function submitBtnStateChange(){
        const form = document.consultingForm;
        console.log(form);
        if(checkedConsulting.length !== 0 && form.architectural_use.value !== '' && form.consulting_detail_input.value !== ''){
            setSubmitBtnState('');
        }else{
            setSubmitBtnState('disabled');
        }
    }
    
    useEffect(()=>{
        consultingFirst();
        consultingSecond();
    },[]);

    useEffect(()=>{
        submitBtnStateChange();
    },[checkedConsulting]);
    
    return(
        <div className={`consulting_con`} onClick={consulting_select_state}>
            <form className='consultingForm' name="consultingForm">
                <div className="width_con">
                    <div className="left_con">
                        <div className="select_title">컨설팅 항목 선택</div>
                        <div className="consulting_select">
                            <div className="consulting_select_title" onClick={selectTitleOnclick}>{checkedConsulting.length === 0 ? text :
                            checkedConsulting.map((item,index)=>{
                                return(item+', ')
                            })
                            }</div> 
                            <ul className={`consulting_ul ${consultingUlState === true ? '':'hide'}`}>
                                {checkbox_list.map((item,index)=>{
                                    return(
                                        <li className="checkbox_list" key={index}>
                                            <div className="title">{item}</div>
                                            <input type="checkbox" className="checkbox" id={`check${index}`} value={item} onChange={checkBoxOnChange} name="consulting_items"></input>
                                            <label htmlFor={`check${index}`}></label>
                                        </li>
                                    )

                                })}
                            </ul>
                        </div>
                        <div className="select_title">건축 용도 선택</div>
                        <div className='input_cover'>
                            <input className="consulting_input" placeholder={inputText} name="architectural_use" onChange={submitBtnStateChange}>
                            </input>
                        </div>
                        <div className="select_title">
                            건축예산                    
                            <div className="budget">
                                {budget[0] === budget[1] ? budget_amount[budget[0]] :
                                    budget[0] > budget[1] ? `${budget_amount[budget[1]]} ~ ${budget_amount[budget[0]]}` : 
                                    `${budget_amount[budget[0]]} ~ ${budget_amount[budget[1]]}`
                                }
                            </div>
                        
                        </div>
                        <div className="consulting_budget">
                            <div className="row_input">
                                <div className="cube"><img src={process.env.PUBLIC_URL+"images/map/image_cube.svg"} alt="cube"></img></div>
                                <div className="cube"><img src={process.env.PUBLIC_URL+"images/map/image_cube.svg"} alt="cube"></img></div>
                                <div className="cube"><img src={process.env.PUBLIC_URL+"images/map/image_cube.svg"} alt="cube"></img></div>
                                <div className="cube"><img src={process.env.PUBLIC_URL+"images/map/image_cube.svg"} alt="cube"></img></div>
                                <div className="cube"><img src={process.env.PUBLIC_URL+"images/map/image_cube.svg"} alt="cube"></img></div>
                                <div
                                    className="row_dark"
                                    style={{
                                        left: budget[0] === budget[1]
                                        ? (25 * budget[0]) + '%'
                                        : (25 * Math.min(budget[0],budget[1]) + "%")
                                        
                                    ,width: budget[0] >= budget[1] ? (25 * (budget[0] - budget[1]) + "%"):(25 * (budget[1] - budget[0]) + "%")}}
                                ></div>

                                <div className="cube_purple" style={{left:(25*budget[0])+'%'}}><img src={process.env.PUBLIC_URL+"images/map/image_cube_purple.svg"} alt="cube"></img></div>
                                <input className="range" name="range-a" id="range" type="range" min="0" max="4" value={budget[0]} onInput={(e)=>{setBudget([e.target.value,budget[1]])}}></input>
                                <input className="range" name="range-b" id="range-1b" type="range" min="0" max="4" value={budget[1]} onInput={(e)=>{setBudget([budget[0],e.target.value])}}></input>
                                <div className="cube_purple" style={{left:(25*budget[1])+'%'}}><img src={process.env.PUBLIC_URL+"images/map/image_cube_purple.svg"} alt="cube"></img></div>
                            </div>
                            <div className="budget_text">
                                <div className="left">3억</div>
                                <div className="right">30억</div>
                            </div>

                        </div>
                    </div>
                    <div className="right_con">
                        <div className="select_title">상세 문의</div>
                        <div className='input_cover'>
                            <textarea className="consulting_detail_input" onChange={submitBtnStateChange} placeholder="컨설팅 받고자 하는 항목들에 대한 설명을 입력해주세요.&#13;&#10; &#13;&#10; &#13;&#10;ex)  가지고 있는 땅에 단독 주택을 짓고 싶어요. 자금과 시간이 얼마나 드는지 알고 싶어요." name="consulting_detail_input"></textarea>
                        </div>
                    </div>
                    <div className={`submit_btn ${submitBtnState}`} onClick={consultingSubmit}>확인</div>
                </div>
            </form>
        </div>
    )
}

export default Consulting;